import axios from 'axios';
import * as api from '../services/api';
import { removeLastActivityTime } from '../hooks/auth/inactivityUtils';

const BASE_AUTH_URL = process.env.AUTH_URL;

const REFRESH_TYPES = {
  admin: api.refreshAdminToken,
  user: api.refreshUserToken,
  unknown: async () => {
    try {
      await api.refreshAdminToken();
    } catch (e) {
      await api.refreshUserToken();
    }
  }
};

let isRefreshing = false;
let failedRequests = [];

const processQueue = (error) => {
  failedRequests.forEach((p) => {
    if (error) {
      p.reject(error);
    } else {
      p.resolve();
    }
  });

  failedRequests = [];
};

const interceptorCreator = (type) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalReq = error.config;
      if (error.response.status === 403) {
        window.location = `${BASE_AUTH_URL}/unauthorized`;
        throw error;
      }

      if (error.response.status === 401 && !originalReq._retry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => failedRequests.push({ resolve, reject }))
            .then(() => axios(originalReq))
            .catch((err) => Promise.reject(err));
        }

        isRefreshing = true;
        originalReq._retry = true;

        try {
          await REFRESH_TYPES[type]();
          processQueue();
          return axios(originalReq);
        } catch (err) {
          processQueue(err);
          removeLastActivityTime();
          // refresh token didn't work
          window.location = `${BASE_AUTH_URL}/login/admin?redirectUrl=${encodeURIComponent(window.location)}`;
        } finally {
          isRefreshing = false;
        }
      }

      throw error;
    }
  );
};

export default interceptorCreator;
