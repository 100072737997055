import styled from 'styled-components';
import { FlexMiddle } from '../Flex';

const ButtonHeader = styled(FlexMiddle)`
  justify-content: center;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.gray700};
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: 6px;
  cursor: pointer;
  padding: 0 10px;
  min-width: 36px;
  min-height: 36px;

  ${({ disabled }) => disabled && 'cursor: not-allowed;'}
  ${({ disabled, theme }) => disabled && `color: ${theme.colors.gray600};`}
`;

export default ButtonHeader;
