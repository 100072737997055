import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { removeSpecialCharsOfData } from './securityCheck';

const mapFormatToNumFmt = (format, formattingOptions) => {
  switch (format) {
    case 'number':
      return '0.00';
    case 'percentage':
      return '0.00 %';
    case 'currency':
      return `0.00 ${formattingOptions?.currency || ''}`;
    default:
      return '';
  }
};

const exportExcel = async ({
  baseData,
  name,
  title,
  detailedTable,
  extraSheetTitle,
  summaryColumns,
  showTitle = true,
  addExtraSheet = false,
  extraSheetData = [],
  autoFilter = true,
  showColumnsRowStyle = true,
  asCSV = false,
  columnsDef = [],
  formattingOptions
}) => {
  const workSheetName = (name || '').replace(/[*?:/\\[\]]/g, '');

  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet(workSheetName, { title: 'tet' });

  /* Security check */
  const data = removeSpecialCharsOfData(baseData);

  if (showTitle) {
    worksheet.getRow(1).values = [title ?? workSheetName];
    worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
    worksheet.getCell('A1').font = { size: 18 };
    worksheet.mergeCells(1, 1, 1, Object.keys(data[0]).length);
  }

  worksheet.getRow(showTitle ? 2 : 1).values = Object.keys(detailedTable ? detailedTable[0] : data[0]);

  // todo: detailedTable is not in use. need to be removed.
  worksheet.columns = Object.keys(detailedTable ? detailedTable[0] : data[0]).map((column) => ({
    // 'column' here is the value of the property of the first object
    // in the data array which is already translated to the user language
    key: column
  }));

  // Set the width of each column according to the widest content in that column
  worksheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell((cell) => {
      const length = cell.value ? cell.value.toString().length : 0;
      if (length > maxLength) {
        maxLength = length;
      }
    });
    // 'Header' here has the same translated value as the column key
    const columnDef = columnsDef.find(({ Header }) => Header === column.key);
    column.width = maxLength + 2;
    column.numFmt = mapFormatToNumFmt(columnDef?.format, formattingOptions);
  });

  const rows = (detailedTable || data).map((item) => Object.values(item));

  worksheet.insertRows(showTitle ? 3 : 2, rows);

  if (addExtraSheet) {
    const extraSheet = workbook.addWorksheet(extraSheetTitle);

    extraSheet.getRow(1).values = Object.keys(extraSheetData[0]);
    extraSheet.columns = Object.keys(extraSheetData[0]).map((column) => ({
      key: column
    }));
    extraSheet.insertRows(
      2,
      extraSheetData.map((item) => Object.values(item))
    );
  }

  // filter in excel will be on
  if (autoFilter) {
    worksheet.autoFilter = {
      from: { row: showTitle ? 2 : 1, column: 1 },
      to: { row: showTitle ? 2 : 1, column: worksheet.columns.length }
    };
  }

  // Add a row of summary data
  if (summaryColumns) {
    const summaryRow = worksheet.addRow({});

    summaryColumns.forEach((columnName) => {
      let ranges = [];

      const column = worksheet.getColumn(columnName);

      column.eachCell((cell, rowNumber) => {
        if (![1, summaryRow.number].includes(rowNumber)) {
          ranges.push(cell.address);
        }
      });

      if (ranges.length > 0) {
        summaryRow.getCell(columnName).value = {
          formula: `SUM(${ranges[0]}:${ranges[ranges.length - 1]})`,
          result: 0
        };
      }
    });

    summaryRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'e8e8e8' }
    };

    summaryRow.font = {
      bold: true
    };
  }

  // const imageColumn = worksheet.getColumn(13);

  // // Add the images to the worksheet for each cell in the image column
  // imageColumn.eachCell((cell, rowNumber) => {
  //   if (rowNumber > 1) {
  //     // Skip the header row
  //     const base64Image = cell.value;

  //     const image = workbook.addImage({
  //       base64: base64Image,
  //       extension: 'png' // Adjust the extension as needed
  //     });

  //     worksheet.addImage(image, {
  //       tl: { col: 13, row: rowNumber }, // Position the image in the specified cell
  //       ext: { width: 100, height: 100 } // Width and height of the image
  //     });
  //   }
  // });

  if (showColumnsRowStyle) {
    const row = worksheet.getRow(1);
    row.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'e8e8e8' }
    };

    row.font = {
      bold: true
    };
  }

  workbook.xlsx.writeBuffer().then((bufferData) => {
    const blob = new Blob([bufferData], { type: 'application/octet-stream' });
    saveAs(blob, `${workSheetName}.${asCSV ? 'csv' : 'xlsx'}`);
  });
};

export default exportExcel;
