const getAddressFromLatLng = async (google, lat, lng) => {
  const decoder = new google.maps.Geocoder();
  const response = await decoder.geocode({
    location: {
      lat: parseFloat(lat),
      lng: parseFloat(lng)
    }
  });
  return response?.results?.[0]?.formatted_address;
};

const mapUtils = { getAddressFromLatLng };

export default mapUtils;
